.wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding: 2rem;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.5rem;
}

.card {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    overflow: hidden;
    background-color: #fff;
}

.image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.content {
    padding: 1.5rem;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.description {
    color: #555;
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 1.5rem;
}

.sectionGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.25rem;

    @media (min-width: 640px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.statBox {
    background-color: #f4f4f4;
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;
}

.statBox h3 {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.statBox p {
    font-size: 1.5rem;
}

.propSectionGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.25rem;

    @media (min-width: 640px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.propBox {
    background-color: #e3afaf;
    padding: 1rem;
    border-radius: 0.75rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.propBox h3 {
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.propBox p {
    font-size: 1rem;
}

.ctaButton {
    width: 100%;
    margin-top: 1.5rem;
}

.infoBox {
    background-color: #eaf4fa;
    /* Light blue */
    border-radius: 0.75rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
}

.infoIcons {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.25rem;

    @media (min-width: 640px) {
        grid-template-columns: repeat(5, 1fr);
    }

    .infoIcon {
        height: 150px;
        background-color: #f8faea;
        border: 1px solid #dddddd;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        font-weight: 600;
        font-size: 12px;
        text-transform: capitalize;

        img {
            // @media (max-width: 600px) {
            width: 50px;
            height: 50px;
            // }
        }

        @media (max-width: 600px) {
            width: 60px;
            height: 60px;
            font-size: 9px;
            text-align: center;
        }
    }
}