.button {
    background-color: #1f2937;
    /* Dark gray */
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s ease;
    text-align: center;
    width: 100%;
    display: inline-block;
}

.button:hover {
    background-color: #111827;
    transform: translateY(-1px);
}

.button:active {
    transform: scale(0.98);
}